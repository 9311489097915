import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private STORAGE_KEY = 'user-color-scheme';
  private COLOR_MODE_KEY = '--color-mode';

  isDarkTheme: BehaviorSubject<boolean>;

  constructor(
    private cookieService: CookieService
  ) {
    this.isDarkTheme = new BehaviorSubject<boolean>(
      (this.getUserColorScheme() || this.getCSSCustomProp(this.COLOR_MODE_KEY)) === 'dark'
    );
    this.applySetting();
  }

  private getUserColorScheme() {
    return this.cookieService.check(this.STORAGE_KEY) ? this.cookieService.get(this.STORAGE_KEY) : null;
  }

  private getCSSCustomProp(propKey) {
    let response = getComputedStyle(document.documentElement).getPropertyValue(propKey);

    if (response.length) {
      response = response.replace(/\"/g, '').trim();
    }

    return response;
  }

  private applySetting(passedSetting ?: any) {
    const currentSetting = passedSetting || this.getUserColorScheme();

    if (currentSetting) {
      document.documentElement.setAttribute('data-user-color-scheme', currentSetting);
    }

    return currentSetting;
  }

  private toggleSetting() {
    let currentSetting = this.getUserColorScheme();

    switch (currentSetting) {
      case null:
        currentSetting = this.getCSSCustomProp(this.COLOR_MODE_KEY) === 'dark' ? 'light' : 'dark';
        break;
      case 'light':
        currentSetting = 'dark';
        break;
      case 'dark':
        currentSetting = 'light';
        break;
    }

    this.cookieService.set(this.STORAGE_KEY, currentSetting, 182, '/', null, environment.production, 'Lax');

    return currentSetting;
  }

  public toggleTheme() {
    const newSetting = this.applySetting(this.toggleSetting());
    this.isDarkTheme.next(newSetting === 'dark');
  }
}
