<div (click)="onMouseEvent($event)">
  <div #mainContainer class="main-container">
    <app-main *ngIf="visio && (mainStream | async)" #main
      [type]="mainType" [stream]="mainStream | async"></app-main>
  </div>
  <div #pointersContainer class="pointers-container">
    <app-pointer *ngFor="let pointer of (pointers | async)"
    [style.top]="pointer.y + 'px'" [style.left]="pointer.x + 'px'" [type]="pointer.type"></app-pointer>
  </div>
  <div #secondariesContainer class="secondaries-container">
    <app-secondary *ngFor="let stream of streams | async"
      #secondaries
      [type]="stream.publisher ? 'publisher' : 'subscriber'" [stream]="stream"
      (click)="stopPropagation($event)"
      cdkDrag cdkDragBoundary=".secondaries-container" (cdkDragStarted)="onSecondaryDragStarted(stream)"
      (switchCameraClicked)="switchCamera()" (toggleMicrophoneClicked)="toggleMicrophone()" (toggleCameraClicked)="toggleCamera()">
    </app-secondary>
  </div>
  <div class="top-actions-container" fxLayoutGap="16px">
    <button fxFlex="0 0 auto" type="button" class="tall stop-button" mat-raised-button color="warn" (click)="endExpertise(); stopPropagation($event);">
      <mat-icon matPrefix>call_end</mat-icon>
      {{ 'EXPERTISE.STOP' | translate }}
    </button>
    <ngx-toasted #toastContainer fxFlex="1 1 100%" class="w-100-p"></ngx-toasted>
  </div>
  <div class="bottom-actions-container">
    <app-actions
      *ngIf="!isGuest"
      (takePhotoClicked)="setAudioPlayer();takePhoto()"
      (gotoGalleryClicked)="gotoGallery()"
      (switchCameraClicked)="switchCamera()"
      (toggleMicrophoneClicked)="toggleMicrophone()"
      (click)="stopPropagation($event)">
    </app-actions>
  </div>
  <input #gallery id="gallery" type="file" name="file" accept="image/png, image/jpeg" (change)="resumeFromGallery($event)">
</div>
<div #streamDiv style="position: absolute; top: 0; bottom: 0; opacity: 0; z-index: -999;"></div>