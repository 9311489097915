import { Entity } from './entity';
import { Type, Deserializer, Serializer} from 'typeserializer';
import { DateConverter } from '../type-converters/date.converter';

export class Session extends Entity {
  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  @Serializer(DateConverter.serialize)
  expireAt: Date;

  opentokApiKey: string;

  opentokSessionId: string;

  opentokSessionTokenExpert: string;

  opentokSessionTokenClient: string;

  opentokSessionTokenGuest: string;
}
