import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { WebRTCService, DevicePermission } from '../../../services/webrtc.service';
import { BrowserCompatibilityService } from '../../../services/browser-compatibility.service';
import * as OT from '@opentok/client';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BasicDialogComponent, BasicDialogData } from '../basic-dialog/basic-dialog.component';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.scss']
})
export class ParameterComponent implements AfterViewInit, OnDestroy {
  isTesting = false;
  private subscriptions: Subscription[] = [];

  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  publisher: OT.Publisher;
  stream: OT.Stream;

  audioDevices: any[] = [];
  videoDevices: any[] = [];
  audioLevel = new BehaviorSubject<number>(0);

  selectedAudioDevice = undefined;
  selectedVideoDevice = undefined;

  DevicePermission = DevicePermission;

  constructor(
    private ref: ChangeDetectorRef,
    public browserCompatibilityService: BrowserCompatibilityService,
    public webRTCService: WebRTCService,
    private translateService: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.subscriptions.push(
      this.webRTCService.permissionsStatus.subscribe((permissionStatus) => {
        if (permissionStatus === DevicePermission.Ok) {
          this.webRTCService.getOT().getDevices((err, devices) => {
            if (err) {
              return;
            }

            devices.forEach((device) => {
              if (device.kind === 'audioInput') {
                this.audioDevices.push(device);
              } else if (device.kind === 'videoInput') {
                this.videoDevices.push(device);
              }
            });

            // I supposed that the selected device is the first but cant confirm it

            let selectedAudiodevice = this.audioDevices.find(d => d.deviceId === 'default');
            if (!selectedAudiodevice && this.audioDevices.length > 0) {
              selectedAudiodevice = this.audioDevices[0];
            }
            this.selectedAudioDevice = selectedAudiodevice;

            // let selectedVideoDevice = this.videoDevices.find(d => d.deviceId === 'default');
            // if (!selectedVideoDevice && this.videoDevices.length > 0) {
            //   selectedVideoDevice = this.videoDevices[0];
            // }
            // this.selectedVideoDevice = selectedVideoDevice;
          });
        }
      })
    );

    this.subscriptions.push(
      this.webRTCService.onTestStart.subscribe(() => {
        this.isTesting = true;
      })
    );

    this.subscriptions.push(
      this.webRTCService.onTestEnd.subscribe(() => {
        this.isTesting = false;
        this.initPublisher();
      })
    );
  }

  ngAfterViewInit(): void {
    this.initPublisher();
  }

  initPublisher() {
    this.publisher = this.webRTCService.initPublisher(
      this.publisherDiv.nativeElement,
      {
        publishAudio: true,
        publishVideo: true,
        insertMode: 'append',
        mirror: false,
        style: {
          nameDisplayMode: 'off',
          buttonDisplayMode: 'off',
          audioLevelDisplayMode: 'off',
          archiveStatusDisplayMode: 'off',
          backgroundImageURI: 'on',
        }
      } as OT.PublisherProperties,
      (err) => {
        if (err) {
          let title, content;
          if (err?.name === 'OT_HARDWARE_UNAVAILABLE') {
            title = this.translateService.instant('EXPERTISE.DIALOG_HARDWARE_UNAVAILABLE.TITLE');
            content = this.translateService.instant('EXPERTISE.DIALOG_HARDWARE_UNAVAILABLE.CONTENT');
          } else {
            title = this.translateService.instant('EXPERTISE.API_ERRORS.OTHER');
            content = err?.message;
          }

          const dialog = this.dialog.open(BasicDialogComponent, {
            disableClose: true,
            data: {
              title,
              content,
              negativeButton: this.translateService.instant('SHARED.QUIT'),
              positiveButton: this.translateService.instant('SHARED.REFRESH'),
            } as BasicDialogData
          });

          dialog.afterClosed().subscribe(result => {
            if (result) {
              window.document.location.reload();
            } else {
              this.router.navigate(['']);
            }
          });
        }
      }
    );


    // Force environement facing, to change in publisher parameter if the sdk allow it one day
    this.publisher.once('videoElementCreated', (stream) => {
      this.switchCamera();
    });

    let lastUpdate = + new Date();
    let max = 0;
    this.publisher.on('audioLevelUpdated', (event) => {
      if (event.audioLevel) {
        const current = + new Date();
        if (event.audioLevel > max) {
          max = event.audioLevel;
        }

        if ((current - lastUpdate) > 200) {
          this.audioLevel.next(max * 100);
          max = 0;
          lastUpdate = + new Date();
          if (!(this.ref as any).destroyed) {
            this.ref.detectChanges();
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => {
      s.unsubscribe();
    });

    if (this.publisher) {
      this.publisher.off('audioLevelUpdated');
      this.publisher.destroy();
    }
  }

  switchCamera() {
    if (this.publisher) {
      const self = this;
      this.publisher.cycleVideo().then((device) => {
        const newDevice = self.videoDevices.find(d => d.deviceId === device.deviceId);
        if (newDevice) {
          self.selectedVideoDevice = newDevice;
        }
      });
    }
  }

  selectMicrophone(event) {
    if (this.publisher && event.value) {
      const self = this;
      const deviceId = event.value;
      this.publisher.setAudioSource(deviceId).then(res => {
        const newDevice = self.audioDevices.find(d => d.deviceId === deviceId);
        if (newDevice) {
          self.selectedAudioDevice = newDevice;
        }
      });
    }
  }

  showSelectedVideoDevice(): string {
    if (!this.selectedVideoDevice) {
      return '';
    }
    const selectedDevice = this.videoDevices.find(d => d.deviceId === this.selectedVideoDevice.deviceId);
    return (selectedDevice && selectedDevice.label) ? selectedDevice.label : '';
  }

  isSameDevices(): boolean {
    try {
      if (this.browserCompatibilityService.isMobile) {
        return true;
      }

      const selectedVideoDevice = this.selectedVideoDevice;
      const selectedAudioDevice = this.selectedAudioDevice;
      return selectedVideoDevice.label.indexOf(selectedAudioDevice.label) !== -1
        ||
        selectedAudioDevice.label.indexOf(selectedVideoDevice.label) !== -1;
    } catch (error) {
      return true;
    }
  }
}
