import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './services/theme.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private supportedLang = ['en', 'fr'];

  constructor(
    private translateService: TranslateService,
    private themeService: ThemeService
    ) {
    this.translateService.addLangs(this.supportedLang);
    this.translateService.setDefaultLang('en');
    this.translateService.use(this.translateService.getBrowserLang());

    document.title = 'Visio ' + environment.plateform;
  }
}
