import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserCompatibilityService {
  browserName = ((agent) => {
    switch (true) {
      case agent.indexOf('edge') > -1: return 'MS Edge';
      case agent.indexOf('edg/') > -1: return 'Edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr: return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome: return 'Chrome';
      case agent.indexOf('trident') > -1: return 'MS IE';
      case agent.indexOf('firefox') > -1: return 'Mozilla Firefox';
      case agent.indexOf('safari') > -1: return 'Safari';
      default: return 'other';
    }
  })(window.navigator.userAgent.toLowerCase());

  isChrome = this.browserName == 'Chrome';
  isChromeIOS = /CriOS/.test(navigator.userAgent);
  isChromiumBased = !!(window as any).chrome && !/Edge/.test(navigator.userAgent);
  isFirefox = this.browserName == 'Mozilla Firefox';
  isEdge = this.browserName == 'Edge';
  isMsEdge = this.browserName == 'MS Edge';
  isIE = this.browserName == 'MS IE';
  isOpera = this.browserName == 'Opera';
  isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent);
  isSafariIOS = /(iPhone|iPad|iPod)/.test(navigator.platform)
  && /WebKit/i.test(window.navigator.userAgent)
  && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent));

  isMobile = /Android/.test(navigator.userAgent) || /(iPhone|iPad|iPod)/.test(navigator.platform);
  isAndroid = /Android/.test(navigator.userAgent);
  isIOS = /(iPhone|iPad|iPod)/.test(navigator.platform);
  isIphone = /(iPhone)/.test(navigator.platform);
  isIpad = /(iPad)/.test(navigator.platform);
  isIpod = /(iPod)/.test(navigator.platform);
  isCordova = !!(window as any).cordova;

  device: Device = undefined;

  constructor(
    private translateService: TranslateService
  ) {
    try {
      const DeviceDetector = require('device-detector-js');
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      this.device = device;
    } catch (err) {}
  }

  public isBrowserCompatible(): boolean {
    if (this.isAndroid) {
      return this.isChromiumBased;
    } else if (this.isIOS) {
      return true;
    } else {
      return this.isSafari || this.isChromiumBased || this.isFirefox;
    }
  }

  public getDeviceName(): string {
    try {
      const device = this.device;
      if (device) {
        return [device.device.brand, device.device.model].filter((it) => it && it.length > 0).join(' ') +
        ' (' + device.os.name + ' ' + device.os.version + ') (web)';
      } else if (this.isIphone || this.isIpad) {
        const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        const version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)].join('.');
        return (this.isIphone ? 'iPhone' : 'iPad') + ' (iOS ' + version + ') (web)';
      } else if (this.isAndroid) {
        return 'Android (web)';
      } else {
        return this.translateService.instant('SHARED.UNKNOWN_DEVICE') + ' (web)';
      }
    } catch (error) {
      return this.translateService.instant('SHARED.UNKNOWN_DEVICE') + ' (web)';
    }
  }
}

export interface Device {
  client: {
    type: string,
    name: string,
    version: string,
    engine: string,
    engineVersion: string
  };
  os: {
    name: string,
    version: string,
    platform: string
  };
  device: {
    type: string,
    brand: string,
    model: string
  };
}
