import { Entity } from './entity';
import { InfoClient } from './info-client';

export class Client extends Entity {
  remoteId?: number;

  name: string;

  phone?: string;

  mail?: string;

  token: string;

  infoClient: InfoClient;
}
