<div
    class="content"
    [class.me]="isMe"
    [class.expert]="isExpert"
    [class.insured]="isInsured"
    [class.guest]="isGuest">
    <div #streamDiv></div>
    <p class="header">{{ headerText | translate }}</p>

    <div *ngIf="isMe" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" class="actions">
      <button *ngIf="webRTCService.hasVideo" mat-icon-button aria-label="Switch camera" (click)="switchCamera()">
        <mat-icon>switch_camera</mat-icon>
      </button>
      <button mat-icon-button aria-label="Camera ON/OFF" (click)="toggleCamera()">
        <mat-icon>{{ webRTCService.hasVideo ? 'videocam' : 'videocam_off' }}</mat-icon>
      </button>
      <button mat-icon-button aria-label="Microphone ON/OFF" (click)="toggleMicrophone()">
        <mat-icon>{{ webRTCService.hasAudio ? 'mic' : 'mic_off' }}</mat-icon>
      </button>
    </div>
</div>
