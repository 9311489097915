import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  OnInit,
  NgZone,
  ViewChildren,
  QueryList
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../services/utils.class';
import { WebRTCService, DevicePermission } from '../../services/webrtc.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { BasicDialogComponent, BasicDialogData } from '../shared-components/basic-dialog/basic-dialog.component';
import { environment } from '../../../environments/environment';
import { Subscription, BehaviorSubject, Observable, combineLatest, interval } from 'rxjs';
import { LadDialogComponent, LadDialogData } from './lad-dialog/lad-dialog.component';
import { BrowserCompatibilityService } from '../../services/browser-compatibility.service';
import { NgxToastedComponent, Toast } from 'ngx-toasted';
import { PhoneService } from 'src/app/services/phone.service';
import { ApiService } from '../../services/api.service';
import { Visio } from '../../models/visio';
import { ConnectionType, Plateform } from 'src/app/models/info-client';
import { take } from 'rxjs/operators';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { HostListener } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Stream, Publisher, Subscriber } from '@opentok/client';
import { SecondaryComponent } from './secondary/secondary.component';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss'],
  providers: [WebRTCService]
})
export class ExpertiseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('streamDiv') streamDiv: ElementRef;

  subscriptions: Subscription[] = [];
  locationWatchId = undefined;
  lastLat = null;
  lastLong = null;
  gpsPerm: boolean = null;

  private hasBeenInit = false;
  private name: string;
  private phone: string;
  private dossier?: string;
  private dossierId?: string;
  token: string;
  visio: Visio;
  private busyUploading = false;
  private endedByUser = true;
  private intervalIdPing;
  private intervalPing = 20000;
  private oldDialog;
  isGuest = false;

  @ViewChild('gallery', { static: true }) galleryInput: ElementRef;
  @ViewChild('pointersContainer') pointersContainer: ElementRef;
  @ViewChild('toastContainer', { read: NgxToastedComponent }) toastContainer: NgxToastedComponent;
  @ViewChild('mainContainer', { static: true }) mainContainer: ElementRef;
  @ViewChild('secondariesContainer', { static: true }) secondariesContainer: ElementRef;
  @ViewChildren('secondaries') secondariesComponent: QueryList<SecondaryComponent>;

  pointers: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public mainType: 'publisher' | 'subscriber' = 'publisher';
  public mainStream: BehaviorSubject<Stream> = new BehaviorSubject<Stream>(null);

  public get streams(): Observable<Stream[]> {
    return combineLatest([
      this.mainStream,
      this.webRTCService.currentStreams
    ]).pipe(
      map((value) => {
        const mainStream = value[0];
        const streams = value[1];
        return streams.filter((s: Stream) => !mainStream || s.streamId !== mainStream?.streamId);
      })
    );
  }

  private audioPlayer;
  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    // 100% hack : This is to trick iOS and play sound AFTER even if not from user interaction
    this.setAudioPlayer();
  }

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private phoneService: PhoneService,
    private translateService: TranslateService,
    private browserCompatibilityService: BrowserCompatibilityService,
    public webRTCService: WebRTCService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private lightbox: Lightbox,
    private lightboxConfig: LightboxConfig
  ) {
    this.lightboxConfig.fitImageInViewPort = true;
    this.lightboxConfig.showImageNumberLabel = true;
    this.lightboxConfig.wrapAround = false;
    this.lightboxConfig.centerVertically = true;

    if (this.route.snapshot.routeConfig.path == 'gexpertise') {
      this.isGuest = true;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.webRTCService.onSessionDisconnectedEmitter.subscribe((event) => {
        if (event.reason === 'forceDisconnected') {
          this.ngZone.run(() => this.endExpertise(true));
        }
      })
    );
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);

    this.subscriptions.push(
      this.webRTCService.onStreamCreatedEmitter.subscribe((stream: OT.Stream) => {
        if (this.isGuest && !this.mainStream.value && stream?.connection?.data === 'type=assure') {
          this.mainType = 'subscriber';
          this.mainStream.next(stream);
        }

        setTimeout(() => {
          // Prevent overlapping secondaries
          const secondaryComponent = this.secondariesComponent.find((item: SecondaryComponent) => item.stream?.streamId === stream.streamId);
          if (secondaryComponent && secondaryComponent.element && secondaryComponent.element.nativeElement) {
            const nativeElement = secondaryComponent.element.nativeElement;
            let componentRect: DOMRect = nativeElement.getBoundingClientRect();
            const rects: DOMRect[] = this.secondariesComponent
              .filter((item: SecondaryComponent) => item.stream.streamId !== stream.streamId)
              .map(
                (item: SecondaryComponent) => {
                  return item.element && item.element.nativeElement ?
                    item.element.nativeElement.getBoundingClientRect() :
                    null;
                }
              )
              .filter((item) => item !== null);

            rects.forEach(rect => {
              if (Utils.doesRectsIntersect(componentRect, rect)) {
                nativeElement.style.top = (rect.top + rect.height + 8) + 'px';
                componentRect = nativeElement.getBoundingClientRect();
              }
            });
          }
        }, 1000);
      })
    );

    if (this.isGuest) {
      this.subscriptions.push(
        this.webRTCService.onStreamDestroyedEmitter.subscribe((stream: OT.Stream) => {
          if (this.isGuest && this.mainStream.value && stream?.connection?.data === 'type=assure') {
            this.mainStream.next(null);
          }
        })
      );
    }


    if (!this.isGuest) {
      this.subscriptions.push(
        this.webRTCService.currentPublisherStream.subscribe((stream: OT.Stream) => {
          this.mainType = 'publisher';
          this.mainStream.next(stream);
        })
      );
    }


    this.subscriptions.push(
      this.webRTCService.onSignalEventEmitter.subscribe((event) => {
        this.ngZone.run(() => {
          this.handleSignal(event);
        });
      })
    );

    this.subscriptions.push(
      this.webRTCService.onConnectionCreatedEmitter.subscribe((event) => {
        const session = this.webRTCService.currentSessionObject;
        if (session && session.connection.connectionId !== event.connection.connectionId) {
          if (event.connection.data === 'type=expert') {
            this.showToast('connection-expert', 'EXPERTISE.TOAST.EXPERT_CONNECTED', 4000, 'success');
          } else if (event.connection.data === 'type=assure') {
            this.showToast('connection-insured', 'EXPERTISE.TOAST.INSURED_CONNECTED', 4000, 'success');
          } else if (event.connection.data === 'type=guest') {
            this.showToast('connection-guest', 'EXPERTISE.TOAST.GUEST_CONNECTED', 4000, 'info');
          } else {
            this.showToast('connection-user', 'EXPERTISE.TOAST.USER_CONNECTED', 2000, 'info');
          }
          this.sendDeviceInfo(this.lastLat, this.lastLong, true, false);
        }
      })
    );

    this.subscriptions.push(
      this.webRTCService.onConnectionDestroyedEmitter.subscribe((event) => {
        const session = this.webRTCService.currentSessionObject;
        if (session && session.connection.connectionId !== event.connection.connectionId) {
          if (event.connection.data === 'type=expert') {
            this.showToast('connection-expert', 'EXPERTISE.TOAST.EXPERT_DISCONNECTED', 0, 'error');
          } else if (event.connection.data === 'type=assure') {
            this.showToast('connection-insured', 'EXPERTISE.TOAST.INSURED_DISCONNECTED', 0, 'error');
          } else if (event.connection.data === 'type=guest') {
            this.showToast('connection-guest', 'EXPERTISE.TOAST.GUEST_DISCONNECTED', 2000, 'info');
          } else {
            this.showToast('connection-user', 'EXPERTISE.TOAST.USER_DISCONNECTED', 2000, 'info');
          }
        }
      })
    );

    this.subscriptions.push(
      this.webRTCService.onSessionConnectedEmitter.subscribe((event) => {
        this.showToast('connection-self', 'EXPERTISE.TOAST.CONNECTED', 1000, 'success');
      })
    );

    this.subscriptions.push(
      this.webRTCService.onSessionReconnectingEmitter.subscribe((event) => {
        this.showToast('connection-self', 'EXPERTISE.TOAST.RECONNECTING', 0, 'error');
      })
    );

    this.subscriptions.push(
      this.webRTCService.onSessionReconnectedEmitter.subscribe((event) => {
        this.showToast('connection-self', 'EXPERTISE.TOAST.RECONNECTED', 2000, 'success');
      })
    );

    this.subscriptions.push(
      this.webRTCService.onPublisherStreamCreatedEmitter.subscribe((event) => {
        if (!this.isGuest) {
          this.apiService.pingVisio(this.token).pipe(take(1)).subscribe();
          this.intervalIdPing = setInterval(() => {
            this.apiService.pingVisio(this.token).pipe(take(1)).subscribe();
          }, this.intervalPing);
        }
      })
    );

    const path = this.route.snapshot.routeConfig.path;
    if (path === 'expertise') {
      // Classic visio
      try {
        const base64 = this.route.snapshot.queryParams.u;
        const name = this.route.snapshot.queryParams.name;
        const phone = this.route.snapshot.queryParams.phone;
        const dossier = this.route.snapshot.queryParams.cc;
        const dossierId = this.route.snapshot.queryParams.cd;
        if (base64) {
          const userInfo = Utils.deserializeNameAndPhone(base64);
          if (userInfo.name && userInfo.phone) {
            this.name = userInfo.name;
            this.phone = this.phoneService.formatPhoneForApi(userInfo.phone);
            this.dossier = userInfo.dossier;
            this.dossierId = userInfo.dossierId;
          } else {
            throw new Error('Bad user info');
          }
        } else if (name && phone) {
          this.name = name;
          this.phone = this.phoneService.formatPhoneForApi(phone);
          this.dossier = dossier;
          this.dossierId = dossierId;
        } else {
          throw new Error('Missing user info');
        }
      } catch (error) {
        this.router.navigate(['login']);
        return;
      }

      if (this.dossier === 'null') {
        this.dossier = null;
      }

      this.onClassicReady();
    } else if (path === 'nexpertise') {
      // New API visio
      try {
        const token = this.route.snapshot.queryParams.t;
        if (!token) {
          throw new Error('No token given');
        }
        this.token = token;
        this.onApiReady(token);
      } catch (error) {
        this.router.navigate(['login']);
        return;
      }
    } else if (path === 'gexpertise') {
      // Guest visio
      try {
        const token = this.route.snapshot.queryParams.t;
        if (!token) {
          throw new Error('No token given');
        }
        this.token = token;
        this.onApiReady(token);
      } catch (error) {
        this.router.navigate(['login']);
        return;
      }
    } else {
      throw new Error('Bad route');
    }
  }

  onClassicReady() {
    this.apiService.createVisio(this.phone, this.name, this.dossier, this.dossierId).subscribe((visio: Visio) => {
      this.visio = visio;
      this.token = visio.client.token;
      this.afterGettingVisio();
    }, (err) => {
      let message;
      switch (err ? err.status : -1) {
        case 403:
          message = this.translateService.instant('EXPERTISE.API_ERRORS.403');
          break;
        default:
          message = this.translateService.instant('EXPERTISE.API_ERRORS.OTHER');
          break;
      }

      const d = this.dialog.open(BasicDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateService.instant('EXPERTISE.API_ERRORS.TITLE'),
          content: message,
          positiveButton: this.translateService.instant('SHARED.OK')
        } as BasicDialogData
      });

      d.afterClosed().subscribe(result => {
        this.router.navigate(['login']);
      });
      this.ref.detectChanges();
    });
  }

  onApiReady(token: string) {
    (this.isGuest ? this.apiService.getVisioGuest(token) : this.apiService.getVisio(token)).subscribe((visio: Visio) => {
      this.visio = visio;
      this.afterGettingVisio();
    }, (err) => {
      let message;
      switch (err ? err.status : -1) {
        case 403:
          message = this.translateService.instant('EXPERTISE.API_ERRORS.403');
          break;
        default:
          message = this.translateService.instant('EXPERTISE.API_ERRORS.OTHER');
          break;
      }

      const d = this.dialog.open(BasicDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateService.instant('EXPERTISE.API_ERRORS.TITLE'),
          content: message,
          positiveButton: this.translateService.instant('SHARED.OK')
        } as BasicDialogData
      });

      d.afterClosed().subscribe(result => {
        this.router.navigate(['login']);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    if (this.locationWatchId) {
      navigator.geolocation.clearWatch(this.locationWatchId);
    }

    if (this.endedByUser && !this.isGuest) {
      this.apiService.endVisio(this.token).pipe(take(1)).subscribe();
    }

    if (this.intervalIdPing) {
      clearInterval(this.intervalIdPing);
    }
  }

  private afterGettingVisio() {
    this.showToast('connection-expert', 'EXPERTISE.TOAST.PLEASE_WAIT_FOR_EXPERT', 0, 'info');

    this.startPublish();

    this.subscriptions.push(
      this.webRTCService.permissionsStatus.subscribe(res => {
        if (res === DevicePermission.Ok && !this.hasBeenInit) {
          this.hasBeenInit = true;
          this.startExpertise();
        } else if (res === DevicePermission.NoDevicesFound) {
          const dialog = this.dialog.open(BasicDialogComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('EXPERTISE.DIALOG_NO_DEVICE.TITLE'),
              content: this.translateService.instant('EXPERTISE.DIALOG_NO_DEVICE.CONTENT'),
              positiveButton: this.translateService.instant('SHARED.OK')
            } as BasicDialogData
          });

          dialog.afterClosed().subscribe(result => {
            this.router.navigate(['login']);
          });

          this.ref.detectChanges();
        } else if (res === DevicePermission.NoPermission) {
          const dialog = this.dialog.open(BasicDialogComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('EXPERTISE.DIALOG_NO_PERMISSION.TITLE'),
              content: this.translateService.instant('EXPERTISE.DIALOG_NO_PERMISSION.CONTENT'),
              negativeButton: this.translateService.instant('SHARED.QUIT'),
              positiveButton: this.translateService.instant('SHARED.REFRESH')
            } as BasicDialogData
          });

          dialog.afterClosed().subscribe(result => {
            if (result) {
              window.document.location.reload();
            } else {
              this.router.navigate(['login']);
            }
          });

          this.ref.detectChanges();
        }
      })
    );
  }

  private startExpertise() {
    try {
      this.webRTCService.setVisio(environment.opentok.keySession, this.visio, this.isGuest).then((session: OT.Session) => {
        this.subscriptions.push(
          this.webRTCService.currentStreams.subscribe((streams) => {
            if (!(this.ref as any).destroyed) {
              this.ref.detectChanges();
            }
          })
        );
        this.webRTCService.connect().then(() => {
          console.log('Ready !');
          this.onReady();
        }, (err) => {
          console.error(err);
          throw err;
        });
      }, (err) => {
        console.error(err);
        throw err;
      });
    } catch (error) {
      const dialog = this.dialog.open(BasicDialogComponent, {
        disableClose: true,
        data: {
          title: this.translateService.instant('EXPERTISE.API_ERRORS.TITLE'),
          content: this.translateService.instant('EXPERTISE.API_ERRORS.OTHER'),
          positiveButton: this.translateService.instant('SHARED.OK')
        } as BasicDialogData
      });
      dialog.afterClosed().subscribe(result => {
        this.router.navigate(['login']);
      });
      this.ref.detectChanges();
    }
  }

  private startPublish() {
    const optkOptions: OT.PublisherProperties = {
        insertMode: 'replace',
        fitMode: 'contain',
        style: {
          nameDisplayMode: 'off',
          buttonDisplayMode: 'off',
          audioLevelDisplayMode: 'off',
          archiveStatusDisplayMode: 'off'
        },
        facingMode: 'environment'
    };

    optkOptions.publishAudio = this.webRTCService.hasAudio;
    optkOptions.publishVideo = this.webRTCService.hasVideo;

    const publisher = this.webRTCService.currentPublisherObject;
    if (!publisher) {
      this.webRTCService.initPublisher(
        this.streamDiv.nativeElement,
        optkOptions,
        (err) => {
          if (err) {
            let title, content;
            if (err?.name === 'OT_HARDWARE_UNAVAILABLE') {
              title = this.translateService.instant('EXPERTISE.DIALOG_HARDWARE_UNAVAILABLE.TITLE');
              content = this.translateService.instant('EXPERTISE.DIALOG_HARDWARE_UNAVAILABLE.CONTENT');
            } else {
              title = this.translateService.instant('EXPERTISE.API_ERRORS.OTHER');
              content = err?.message;
            }

            const dialog = this.dialog.open(BasicDialogComponent, {
              disableClose: true,
              data: {
                title,
                content,
                negativeButton: this.translateService.instant('SHARED.QUIT'),
                positiveButton: this.translateService.instant('SHARED.REFRESH'),
              } as BasicDialogData
            });

            dialog.afterClosed().subscribe(result => {
              if (result) {
                window.document.location.reload();
              } else {
                this.router.navigate(['']);
              }
            });
          }
        }
      );
    }

    const s = combineLatest([
      this.webRTCService.isConnectedOnSession,
      this.webRTCService.currentPublisher,
      interval(1000)
    ]).subscribe((res) => {
      if (this.webRTCService.isConnectedOnSession.value === true && this.webRTCService.currentPublisherObject != null) {
        this.webRTCService.publish((err) => {
          if (err) {
            let title, content;
            if (err?.name === 'OT_HARDWARE_UNAVAILABLE') {
              title = this.translateService.instant('EXPERTISE.DIALOG_HARDWARE_UNAVAILABLE.TITLE');
              content = this.translateService.instant('EXPERTISE.DIALOG_HARDWARE_UNAVAILABLE.CONTENT');
            } else {
              title = this.translateService.instant('EXPERTISE.API_ERRORS.OTHER');
              content = err?.message;
            }

            const dialog = this.dialog.open(BasicDialogComponent, {
              disableClose: true,
              data: {
                title,
                content,
                negativeButton: this.translateService.instant('SHARED.QUIT'),
                positiveButton: this.translateService.instant('SHARED.REFRESH'),
              } as BasicDialogData
            });

            dialog.afterClosed().subscribe(result => {
              if (result) {
                window.document.location.reload();
              } else {
                this.router.navigate(['']);
              }
            });
          }
        });
        s.unsubscribe();
      }
    });
  }

  private onReady() {
    this.sendDeviceInfo(this.lastLat, this.lastLong, false, true);

    let lastSentTime;
    this.locationWatchId = navigator.geolocation.watchPosition(resp => {
        if (resp && resp.coords && resp.coords.latitude && resp.coords.longitude) {
          this.lastLat = resp.coords.latitude;
          this.lastLong = resp.coords.longitude;
          this.gpsPerm = true;
          const currentTime = + new Date();
          if (!lastSentTime || currentTime > lastSentTime + 10000) {
            lastSentTime = currentTime;
            this.sendDeviceInfo(resp.coords.latitude, resp.coords.longitude);
          }
        }
      },
      err => {
        this.gpsPerm = false;
        this.sendDeviceInfo(null, null);
        console.error(err);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }

  public endExpertise(force: boolean = false) {
    if (force) {
      this.endedByUser = false;
      this.router.navigate(['post-expertise']);
      this.ref.detectChanges();
      return;
    }

    const dialog = this.dialog.open(BasicDialogComponent, {
      data: {
        title: this.translateService.instant('EXPERTISE.DIALOG_STOP.TITLE'),
        content: this.translateService.instant('EXPERTISE.DIALOG_STOP.CONTENT'),
        negativeButton: this.translateService.instant('EXPERTISE.DIALOG_STOP.NEGATIVE'),
        positiveButton: this.translateService.instant('EXPERTISE.DIALOG_STOP.POSITIVE')
      } as BasicDialogData
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['post-expertise']);
      } else {
        dialog.close();
      }
    });
    this.ref.detectChanges();
  }

  private handleSignal(event) {
    switch (event.type) {
      case 'signal:pointer':
        if (event.data && typeof event.data === 'string') {
          try {
            event.data = JSON.parse(event.data);
          } catch (err) {
            console.error(err);
          }
        }

        let type = 'other';
        if (event.from && event.from.data) {
          switch (event.from.data) {
            case 'type=expert':
              type = 'expert';
              break;
            case 'type=guest':
              type = 'guest';
              break;
            case 'type=assure':
              type = 'insured';
              break;
          }
        }

        if (event.data && event.data.x && event.data.y) {
          this.clearPointers(type);
          this.createPointer(event.data.x, event.data.y, type);
        }
        break;
      case 'signal:clear':
        if (event.data && typeof event.data === 'string') {
          try {
            event.data = JSON.parse(event.data);
          } catch (err) {
            console.error(err);
          }
        }

        this.clearPointers(event.data.sendBy);
        break;
      case 'signal:capture':
        if (this.isGuest) {
          break;
        }
        if (event.data === 'force') {
          this.takePhoto();
        } else {
          this.askGallery();
        }
        break;
      case 'signal:la':
        if (this.isGuest) {
          break;
        }
        this.openLAD(event.data);
        break;
      case 'signal:image':
        if (this.isGuest) {
          break;
        }
        this.openImage(event.data);
        break;
      case 'signal:flash':
        if (this.isGuest) {
          break;
        }
        console.warn('Flash functionalitie is not available for web devices');
        break;
    }
  }

  private createPointer(x, y, type) {
    let calculatedX;
    let calculatedY;

    const videoPositions = this.getFluxVideoRect();
    if (!videoPositions) {
      return;
    }

    calculatedX = (videoPositions.left + x * videoPositions.width) - 8;
    calculatedY = (videoPositions.top + y * videoPositions.height) - 8;

    let newVal = this.pointers.value;
    if (!newVal) {
      newVal = [];
    }

    newVal.push({ x: Math.trunc(calculatedX), y: Math.trunc(calculatedY), type });

    this.pointers.next(newVal);

    this.ref.detectChanges();
  }

  private clearPointers(type) {
    switch (type) {
      case 'expert':
        this.pointers.next(this.pointers.value.filter((p) => p.type !== 'expert'));
        break;
      case 'guest':
        this.pointers.next(this.pointers.value.filter((p) => p.type !== 'guest'));
        break;
      case 'insured':
        this.pointers.next(this.pointers.value.filter((p) => p.type !== 'insured'));
        break;
      default:
        this.pointers.next([]);
        break;
    }
  }

  private openLAD(url) {
    if (this.isGuest) {
      return;
    }

    if (url === 'close' && this.oldDialog) {
      this.oldDialog.close();
      this.oldDialog = null;
      return;
    }

    const dialog = this.dialog.open(LadDialogComponent, {
      disableClose: true,
      minWidth: '95%',
      minHeight: '95%',
      maxWidth: '95%',
      maxHeight: '95%',
      width: '95%',
      height: '95%',
      data: {
        url
      } as LadDialogData
    });
    this.oldDialog = dialog;

    dialog.afterClosed().subscribe(result => { });
    this.ref.detectChanges();
  }

  private openImage(url) {
    this.lightbox.open([{ src: url, thumb: url, downloadUrl: url }], 0);
  }

  public takePhoto() {
    if (this.isGuest) {
      return;
    }

    if (this.busyUploading) {
      return;
    }
    this.busyUploading = true;

    try {
      const publisher = this.webRTCService.currentPublisherObject;
      if (!publisher) {
        console.error('Tried to take photo while no publisher has been initialized');
        this.busyUploading = false;
        return;
      }

      const base64 = publisher.getImgData(); // Doc say its always a PNG
      const file: File = Utils.dataURLtoFile(
        'data:image/png;base64,' + base64,
        Utils.generateUuid() + '.png'
      );

      this.playShutterSound();

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENDING', 0, 'info');

      this.apiService.sendImage(this.token, this.visio.id, file, false).subscribe((res) => {
        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENT', 2000, 'success');

        const session = this.webRTCService.currentSessionObject;
        if (session) {
          session.signal({ type: 'new-image', data: JSON.stringify(res) }, null);
        }
      }, (err) => {
        console.error(err);
        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
      });
    } catch (err) {
      console.error(err);
      this.busyUploading = false;

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
    }
  }

  private playShutterSound() {
    try {
      this.audioPlayer.src = '../../../assets/audio/shutter.wav';
      this.audioPlayer.play();
    } catch (error) {}
  }

  public askGallery() {
    if (this.isGuest) {
      return;
    }

    this.dialog.open(BasicDialogComponent, {
      data: {
        title: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.TITLE'),
        content: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.CONTENT'),
        negativeButton: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.NEGATIVE'),
        positiveButton: this.translateService.instant('EXPERTISE.DIALOG_GALLERY.POSITIVE'),
        elementToClick: this.galleryInput.nativeElement
      } as BasicDialogData
    });

    this.ref.detectChanges();
  }

  public gotoGallery() {
    if (this.isGuest) {
      return;
    }

    this.galleryInput.nativeElement.click();
  }

  public resumeFromGallery(event) {
    if (this.isGuest) {
      return;
    }

    if (this.busyUploading) {
      return;
    }
    this.busyUploading = true;

    try {
      const file: File = event.target.files[0];
      this.galleryInput.nativeElement.value = '';

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENDING', 0, 'info');

      this.apiService.sendImage(this.token, this.visio.id, file, true).subscribe((res) => {
        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_SENT', 2000, 'success');

        const session = this.webRTCService.currentSessionObject;
        if (session) {
          session.signal({ type: 'new-image', data: JSON.stringify(res) }, null);
        }
      }, (err) => {
        console.error(err);

        this.busyUploading = false;

        this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
      });
    } catch (err) {
      console.error(err);
      this.busyUploading = false;

      this.showToast('image-sending', 'EXPERTISE.TOAST.IMAGE_ERROR', 4000, 'error');
    }
  }

  public switchCamera() {
    const publisher = this.webRTCService.currentPublisherObject;
    if (!publisher) {
      console.error('Tried to switch camera while no publisher has been initialized');
      return;
    }

    publisher.cycleVideo();
  }

  public toggleMicrophone() {
    this.webRTCService.toggleAudio();
  }

  public toggleCamera() {
    this.webRTCService.toggleCamera();
  }

  private getFluxVideoRect(): any {
    // ONLY WORK WITH FITMODE: contain
    const mainStream = this.mainStream.value;
    if (!mainStream || !mainStream.streamId) {
      return null;
    }

    const publisher = this.webRTCService.currentPublisherObject as any;
    const subscribers = this.webRTCService.currentSubscribers.value as any;
    const element: Publisher | Subscriber = [publisher].concat(subscribers).find((e: any) => {
      if (e instanceof Publisher) {
        return e.stream && e.stream.streamId === mainStream.streamId;
      } else if (e instanceof Subscriber) {
        return e.stream && e.stream.streamId === mainStream.streamId;
      } else {
        return false;
      }
    });

    if (!element) {
      return null;
    }

    const rectVideoElement = this.mainContainer.nativeElement.getBoundingClientRect();
    const aspectRatioFlux = element.videoWidth() / element.videoHeight();
    const aspectRatioVideoElement = rectVideoElement.width / rectVideoElement.height;

    // Code with orientation:
    // const orientation = '0';
    // const aspectRatioFlux = orientation === '90' || orientation === '270' ?
    // container.videoHeight() / container.videoWidth() :
    // container.videoWidth() / container.videoHeight();
    // const aspectRatioVideoElement = orientation === '90' || orientation === '270' ?
    // rectVideoElement.height / rectVideoElement.width :
    // rectVideoElement.width / rectVideoElement.height;

    let realWidthFlux;
    let realHeightFlux;
    let realXFlux;
    let realYFlux;
    if (aspectRatioFlux > aspectRatioVideoElement) {
      realWidthFlux = rectVideoElement.width;
      realHeightFlux = realWidthFlux / aspectRatioFlux;
      realXFlux = rectVideoElement.left;
      realYFlux = (rectVideoElement.height - realHeightFlux) / 2 + rectVideoElement.top;
    } else {
      realHeightFlux = rectVideoElement.height;
      realWidthFlux = realHeightFlux * aspectRatioFlux;
      realYFlux = rectVideoElement.top;
      realXFlux = (rectVideoElement.width - realWidthFlux) / 2 + rectVideoElement.left;
    }
    return new DOMRect(realXFlux, realYFlux, realWidthFlux, realHeightFlux);
  }

  private showToast(id: string = null, message: string, duration: number = 2000, type: string = 'info') {
    if (this.isGuest) {
      return;
    }

    this.ngZone.run(() => {
      const toast = {
        id,
        message: this.translateService.instant(message),
        duration,
        type,
      } as Toast;

      this.toastContainer.addToast.emit(toast);
    });
  }

  private sendDeviceInfo(x: number = null, y: number = null, withSignalCall: boolean = true, withApiCall: boolean = true) {
    if (this.isGuest) {
      return;
    }

    if (withSignalCall) {
      try {
        const session = this.webRTCService.currentSessionObject;
        if (session) {
          session.signal({
            type: 'platform',
            data: 'web',
          }, null);

          session.signal({
            type: 'device',
            data: this.browserCompatibilityService.getDeviceName(),
          }, null);

          session.signal({
            type: 'battery',
            data: this.translateService.instant('EXPERTISE.SIGNALS.BATTERY_UNKNOWN'),
          }, null);

          session.signal({
            type: 'network',
            data: this.translateService.instant('EXPERTISE.SIGNALS.NETWORK_UNKNOWN'),
          }, null);

          if (this.gpsPerm !== null) {
            session.signal({
              type: 'gpsPerm',
              data: this.gpsPerm ? '1' : '0',
            }, null);
          }

          if (x && y) {
            session.signal({
              type: 'gps',
              data: x + ':' + y,
            }, null);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (withApiCall) {
      const infoClient = this.visio.client.infoClient;
      infoClient.signalStrength = null;
      infoClient.batteryLevel = null;
      infoClient.plateform = Plateform.WEB;
      infoClient.connectionType = ConnectionType.UNKNOWN;
      infoClient.phoneType = this.browserCompatibilityService.getDeviceName();
      infoClient.latitude = x;
      infoClient.longitude = y;
      infoClient.gpsPerm = this.gpsPerm;

      this.apiService.updateInfoClient(this.token, infoClient).subscribe((res) => {
        console.log(res);
      }, (err) => {
        console.error(err);
      });
    }
  }

  public onSubscriberDragStarted(stream: OT.Stream) {
    this.webRTCService.putStreamOnTop(stream);
  }

  public onSecondaryDragStarted(stream: OT.Stream, isPublisher = false) {
    this.webRTCService.putStreamOnTop(isPublisher ? this.webRTCService.currentPublisherObject.stream : stream);
  }

  public onMouseEvent(event) {
    const videoPositions = this.getFluxVideoRect();
    if (!videoPositions) {
      return;
    }

    const calculatedX = (event.x - videoPositions.left) / videoPositions.width;
    const calculatedY = (event.y - videoPositions.top) / videoPositions.height;

    if (calculatedX >= 0 && calculatedX <= 1 && calculatedY >= 0 && calculatedY <= 1) {
      this.sendPointer(calculatedX, calculatedY);
    }
  }

  private sendPointer(x, y) {
    const session = this.webRTCService.currentSessionObject;
    const sender = this.isGuest ? 'guest' : 'insured'
    if (session) {
      session.signal({
        type: 'pointer',
        data: JSON.stringify({
          x,
          y,
          sendBy: sender
        }),
      }, null);

      this.clearPointers(sender);
      this.createPointer(x, y, sender);
    }
  }

  public stopPropagation(event) {
    this.setAudioPlayer();
    event.stopPropagation();
  }

  public setAudioPlayer() {
    try {
      if (!this.audioPlayer) {
        this.audioPlayer = new Audio();
        this.audioPlayer.play();
      }
    } catch (error) {}
  }
}
