import { Type, Deserializer, Serializer} from 'typeserializer';
import { DateConverter } from '../type-converters/date.converter';

export class Entity {
  id: number;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  @Serializer(DateConverter.serialize)
  createdAt: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  @Serializer(DateConverter.serialize)
  updatedAt: Date;
}
