import { Component, OnDestroy } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '../../services/utils.class';
import { CookieService } from 'ngx-cookie-service';
import { PhoneService } from '../../services/phone.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  form = new FormGroup({
    name: new FormControl<string>(null, [Validators.required]),
    phone: new FormControl<string>(null, [Validators.required, this.phoneService.phoneNumberValidator()]),
    originalDossier: new FormControl<string>(null, [Validators.pattern(Utils.dossierRegex)]),
    dossier: new FormControl<string>(null, [Validators.pattern(Utils.dossierRegex)]),
    id: new FormControl<string>(null)
  });

  dummyStatus = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private phoneService: PhoneService
  ) {
    let paramName: string;
    let paramPhone: string;
    let paramDossier: string;
    let paramDossierId: string;

    try {
      const base64 = this.route.snapshot.queryParams.u;
      const name = this.route.snapshot.queryParams.name;
      const phone = this.route.snapshot.queryParams.phone;
      const dossier = this.route.snapshot.queryParams.cc;
      const dossierId = this.route.snapshot.queryParams.cd;

      if (base64) {
        const userInfo = Utils.deserializeNameAndPhone(base64);
        if (userInfo.name && userInfo.phone) {
          paramName = userInfo.name;
          paramPhone = this.phoneService.formatPhoneForUser(userInfo.phone);
          paramDossier = userInfo.dossier;
          paramDossierId = userInfo.dossierId;
        }
      } else if (name && phone) {
        paramName = name;
        paramPhone = this.phoneService.formatPhoneForUser(phone);
        paramDossier = dossier === 'null' ? null : dossier;
        paramDossierId = dossierId === 'null' ? null : dossierId;
      }
    } catch (error) { }

    let cookieName: string;
    let cookiePhone: string;
    let cookieDossier: string;
    let cookieDossierId: string;

    try {
      if (this.cookieService.check('name')) {
        cookieName = this.cookieService.get('name');
      }

      if (this.cookieService.check('phone')) {
        cookiePhone = this.phoneService.formatPhoneForUser(this.cookieService.get('phone'));
      }

      if (this.cookieService.check('dossier')) {
        cookieDossier = this.cookieService.get('dossier');
      }

      if (this.cookieService.check('dossierId')) {
        cookieDossierId = this.cookieService.get('dossierId');
      }
    } catch (error) { }

    this.form.controls.name.setValue(paramName ? paramName : (cookieName ? cookieName : null));
    this.form.controls.phone.setValue(paramPhone ? paramPhone : (cookiePhone ? cookiePhone : null));
    this.form.controls.originalDossier.setValue(paramDossier ? paramDossier : (cookieDossier ? cookieDossier : null));
    this.form.controls.dossier.setValue(paramDossier ? paramDossier : (cookieDossier ? cookieDossier : null));
    this.form.controls.id.setValue(paramDossierId ? paramDossierId : (cookieDossierId ? cookieDossierId : null));

    let oldValue = '';
    this.subscriptions.push(
      this.form.controls.phone.valueChanges.subscribe((value: string) => {
        if (oldValue.length > value.length && isNaN(+oldValue.slice(-1))) {
          // Non number characters deletion, do not interfer
          oldValue = value;
          return;
        }

        const formattedPhoneNumber = this.phoneService.formatPhoneForUserAsYouType(value);
        if (formattedPhoneNumber !== value) {
          this.form.controls.phone.setValue(formattedPhoneNumber, { emitEvent: false });
          oldValue = formattedPhoneNumber;
        } else {
          oldValue = value;
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onKeyPressed(event: KeyboardEvent): boolean {
    // Omit illegal charaters
    return event.key !== ':';
  }

  formSubmit(url: string): void {
    if (!this.form.value.dossier?.length && !!this.form.value.originalDossier) {
      this.form.controls.dossier.setValue(this.form.value.originalDossier);
    }

    if (!this.form.valid) {
      return;
    }

    const phoneFormatted = this.phoneService.formatPhoneForApi(this.form.value.phone);
    const base64Data = {
      name: this.form.value.name,
      phone: phoneFormatted,
      dossier: this.form.value.dossier,
      dossierId: this.form.value.id
    };

    try {
      this.cookieService.set('name', this.form.get('name').value, 30, '/', null, environment.production, 'Lax');
      this.cookieService.set('phone', phoneFormatted, 30, '/', null, environment.production, 'Lax');
      this.cookieService.set('dossier', this.form.value.dossier, 30, '/', null, environment.production, 'Lax');
      if (this.form.value.id) {
        if (this.form.value.dossier !== this.form.value.originalDossier) {
          base64Data.dossierId = null;
          this.cookieService.delete('dossierId', '/', null, environment.production, 'Lax');
        } else {
          this.cookieService.set('dossierId', this.form.value.id, 30, '/', null, environment.production, 'Lax');
        }
      }
    } catch (err) {
      console.error(err);
    }

    this.router.navigate([url], { queryParams: { u: Utils.serializeNameAndPhone(base64Data) } });
  }
}
