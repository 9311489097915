export const environment = {
  name: 'preprod',
  production: false,
  version   : require('../../package.json').version,
  sentryDsn: 'https://fb272a69a49844da96d385f7d5869380@o368540.ingest.sentry.io/5366483',
  api: {
    url: 'https://visio-adenes-api.2dia.fr/api',
  },
  log: {
    url: 'https://sauron.2dia.fr/log',
    token: 'RWpwppYVGXMr73FqqGXj7RXBdW47vmff'
  },
  opentok: {
    keyTest: '45557982',
    keySession: '45608762'
  },
  googleAnalytics: {
      domain: 'auto',
      trackingId: 'UA-145092754-4'
  },
  plateform: 'elex'
};
