import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-settings-dialog',
    templateUrl: './settings-dialog.component.html',
    styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent {
    selectedVideoInput;
    videoInputs: { deviceId: string, label: string }[];

    selectedAudioInput;
    audioInputs: { deviceId: string, label: string }[];

    selectedAudioOutput;
    audioOutputs: { deviceId: string, label: string }[];

    constructor(
        public dialogRef: MatDialogRef<SettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SettingsDialogInterface
    ) {

      OT.getDevices((err, devices) => {
        this.videoInputs = devices.filter(d => d.kind == 'videoInput');
        this.audioInputs = devices.filter(d => d.kind == 'audioInput');

        const publisher = this.data.publisher;
        if (publisher) {
          this.selectedVideoInput = publisher.getVideoSource()?.deviceId;
          this.selectedAudioInput = this.audioInputs.find((d) => d.label === publisher.getAudioSource().label)?.deviceId;
        }
      });

      OT.getAudioOutputDevices().then((devices) => {
        this.audioOutputs = devices;

        OT.getActiveAudioOutputDevice().then((device) => {
          this.selectedAudioOutput = device.deviceId;
        });
      });
    }

    selectedVideoInputChanged() {
      const publisher = this.data.publisher;
      const deviceId = this.selectedVideoInput;
      if (deviceId) {
        publisher?.setVideoSource(deviceId).then(console.log).catch(console.error);
      }
    }

    selectedAudioInputChanged() {
      const publisher = this.data.publisher;
      const deviceId = this.selectedAudioInput;
      if (deviceId) {
        publisher?.setAudioSource(deviceId).then(console.log).catch(console.error);
      }
    }

    selectedAudioOutputChanged() {
      const deviceId = this.selectedAudioOutput;
      if (deviceId) {
        OT.setAudioOutputDevice(deviceId).then(console.log).catch(console.error);
      }
    }
}

export interface SettingsDialogInterface {
  publisher: OT.Publisher;
}