import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-lad-dialog',
  templateUrl: './lad-dialog.component.html',
  styleUrls: ['./lad-dialog.component.scss']
})
export class LadDialogComponent {
  public url;

  public loaded = false;

  constructor(
    public dialogRef: MatDialogRef<LadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LadDialogData,
    private sanitizer: DomSanitizer
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  close() {
    this.dialogRef.close();
  }

  onIframeLoaded() {
    this.loaded = true;
  }
}

export interface LadDialogData {
  url: string;
}
