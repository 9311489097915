import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Publisher, Stream, Subscriber } from '@opentok/client';
import { WebRTCService } from '../../../services/webrtc.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { BasicDialogComponent, BasicDialogData } from '../../shared-components/basic-dialog/basic-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: 'publisher' | 'subscriber';
  @Input() stream: OT.Stream;
  @ViewChild('streamDiv') streamDiv: ElementRef;

  private entity: OT.Subscriber | OT.Publisher;

  private subscriptions: Subscription[] = [];

  private optkOptions: any = {
    insertMode: 'replace',
    fitMode: 'contain',
    width: '100%',
    height: '100%',
    mirror: false,
    style: {
      nameDisplayMode: 'off',
      buttonDisplayMode: 'off',
      audioLevelDisplayMode: 'off',
      archiveStatusDisplayMode: 'off',
      backgroundImageURI: 'on',
    }
  };

  constructor(
    private webRTCService: WebRTCService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    if (this.type === 'publisher') {
      this.optkOptions.subscribeToAudio = false;
      const publisher = this.webRTCService.currentPublisherObject;
      if (publisher) {
        this.webRTCService.subscribe(this.stream, this.streamDiv.nativeElement, this.optkOptions).subscribe({
          error: (err) => { this.error(err); }
        });
      }
    } else if (this.type === 'subscriber') {
      this.optkOptions.audioVolume = 100;
      const subscriber = this.webRTCService.currentSubscribers.value
        .find((s: Subscriber) => s.stream && s.stream.streamId === this.stream.streamId);

      if (subscriber) {
        this.entity = subscriber
      } else {
        this.webRTCService.subscribe(this.stream, this.streamDiv.nativeElement, this.optkOptions).subscribe({
          next: (sub) => {
            this.entity = sub;
          },
          error: (err) => { this.error(err); }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  private error(err) {
    const title = this.translateService.instant('EXPERTISE.API_ERRORS.OTHER');
    const content = err?.message;

    const dialog = this.dialog.open(BasicDialogComponent, {
      disableClose: true,
      data: {
        title,
        content,
        negativeButton: this.translateService.instant('SHARED.QUIT'),
        positiveButton: this.translateService.instant('SHARED.REFRESH'),
      } as BasicDialogData
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        window.document.location.reload();
      } else {
        this.router.navigate(['']);
      }
    });
  }
}
