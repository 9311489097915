import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Visio } from '../models/visio';
import { map } from 'rxjs/operators';
import { deserialize, serialize } from 'typeserializer';
import { InfoClient } from '../models/info-client';
import Compressor from 'compressorjs';
import { Session } from '../models/session';
import { TestResult } from '../models/test-result';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url: string = environment.api.url;

  constructor(
    private http: HttpClient
  ) { }

  public getVisio(token: string): Observable<Visio> {
    return this.http.get<Visio>(this.url + '/visio/client/' + token).pipe(
      map(res => deserialize(JSON.stringify(res), Visio))
    );
  }

  public getVisioGuest(token: string): Observable<Visio> {
    return this.http.get<Visio>(this.url + '/visio/guest/' + token).pipe(
      map(res => deserialize(JSON.stringify(res), Visio))
    );
  }

  public createVisio(phone: string, name: string, dossier?: string, dossierId?: string): Observable<Visio> {
    return this.http.post<Visio>(this.url + '/visio/client/create', {
      client: {
        name,
        phone,
        dossier,
        remoteId: dossierId
      },
      plateform: environment.plateform
    }).pipe(
      map(res => deserialize(JSON.stringify(res), Visio))
    );
  }

  public pingVisio(token: string): Observable<Visio> {
    return this.http.get<Visio>(this.url + '/visio/client/' + token + '/ping').pipe(
      map(res => deserialize(JSON.stringify(res), Visio))
    );
  }

  public endVisio(token: string): Observable<Visio> {
    return this.http.get<Visio>(this.url + '/visio/client/' + token + '/end').pipe(
      map(res => deserialize(JSON.stringify(res), Visio))
    );
  }

  public getTestSession(): Observable<Session> {
    return this.http.post<Session>(this.url + '/visio/test', null).pipe(
      map(res => deserialize(JSON.stringify(res), Session))
    );
  }

  public sendTestResult(testResult: TestResult) {
    return this.http.post<any>(this.url + '/test-result', testResult);
  }

  public sendImage(
    token: string,
    visioId: number,
    image: File,
    compressImage: boolean = true,
    compressionOptions: Compressor.Options = {
      maxHeight: 2000,
      maxWidth: 2000,
      quality: 0.8,
      mimeType: 'image/jpeg',
      checkOrientation: true
    }
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('visioId', '' + visioId);
    if (compressImage) {
      return new Observable<any>(observer => {
        this.compressAndResize(image, compressionOptions).subscribe((compressed: File) => {
          formData.append('image', compressed);
          this.http.post<any>(this.url + '/image/client/' + token, formData).subscribe((o) => {
            observer.next(o);
            observer.complete();
          });
        }, (err) => {
          console.error(err);
          formData.append('image', image);
          this.http.post<any>(this.url + '/image/client/' + token, formData).subscribe((o) => {
            observer.next(o);
            observer.complete();
          });
        });
      });
    } else {
      formData.append('image', image);
      return this.http.post<any>(this.url + '/image/client/' + token, formData).pipe();
    }
  }

  public updateInfoClient(token: string, infoClient: InfoClient) {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    const options = { headers };
    return this.http.put<InfoClient>(this.url + '/info-client/' + token, serialize(infoClient), options).pipe(
      map(res => deserialize(JSON.stringify(res), InfoClient))
    );
  }

  public log(type: string, message: string, data: any) {
    const body = {
      type,
      message,
      data
    };

    this.http.post(
      environment.log.url,
      body,
      {
        headers: {
          'Api-Token': environment.log.token
        }
      }
    ).subscribe((o) => {
      console.log((type && type.length > 0 ? type.charAt(0).toUpperCase() + type.slice(1) : type) + ' logged successfully');
    }, (err) => {
      if (type === 'error') {
        console.error('Oooh sweet irony...', err);
      } else {
        console.error('Error while logging ' + type, err);
      }
    });
  }

  private compressAndResize(image: File | Blob, options: Compressor.Options): Observable<File | Blob> {
    return new Observable<Blob>(observer => {
      options.success = (compressed: Blob) => {
        if (typeof (image as any).name === 'string') {
          const fileImage: File = image as any;
          observer.next(new File([compressed], fileImage.name, {
            lastModified: fileImage.lastModified,
            type: compressed.type,
          }));
        } else {
          observer.next(compressed);
        }
        observer.complete();
      };
      options.error = (error: Error) => {
        observer.error(error);
      };
      /* tslint:disable */
      const compressor = new Compressor(image, options);
      /* tslint:enable */
    });
  }
}
