<div class="card-page">
  <mat-card>
    <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="24px" class="m-16">
      <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
        <app-full-logo></app-full-logo>

        <div>
          <div class="prepare">
            <h2>Préparer ma VisioExpertise</h2>
            <a class="button">1 - Vérifier la connectivité</a>
            <br>
            <p class="alignleft">Appuyer sur le bouton "Tester ma connectivité"<br>
              Rendez-vous sur le lieu du sinistre afin de vérifier la connectivité de votre appareil.<br>
              Lancer plusieurs fois tests avec différent type de connexion à Internet (Wifi, 3G/4G)<br><br>
              Tous les tests négatifs (croix rouge):<br> La réalisation d'une VisioExpertise est compromise.<br>
              Merci de prendre contact avec votre Expert.<br><br>
              Un test positif (coche verte):<br> Vous pouvez réaliser une VisioExpertise, voir étape 2 ci-dessous
            </p><br>

            <a class="button">2 - Lancer la VisioExpertise</a>
            <br>
            <p class="alignleft">À la date et l'heure de votre rendez-vous d'expertise.<br>Assurez-vous d'avoir votre
              appareil chargé.<br>Rendez-vous sur le lieu du sinistre<br>Appuyer sur le bouton "Débuter mon expertise" et
              attendez la connexion de votre expert.
            </p>
            <br>
            <br>
          </div>

          <h2>{{ 'LEGAL_NOTICE.TITLE' | translate }}</h2>

          <p [innerHTML]="'LEGAL_NOTICE.CONTENT' | translate"></p>
        </div>

        <div>
          <a [href]="'LEGAL_NOTICE.BRAND_URL' | translate" target="_blank">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>link</mat-icon>&nbsp;{{ 'LEGAL_NOTICE.BRAND_TEXT_LINK' | translate }}
            </div>
          </a>
          <a [href]="'LEGAL_NOTICE.GC_URL' | translate" target="_blank">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>link</mat-icon>&nbsp;{{ 'LEGAL_NOTICE.GC_TEXT_LINK' | translate }}
            </div>
          </a>
        </div>
      </div>

      <app-copyright [legalNotice]="false"></app-copyright>
    </div>
  </mat-card>
</div>
