import { UserInfo } from '../models/user-info.interface';

export class Utils {
  public static dossierRegex: string | RegExp = /[1-2]\d\w{3}\d{4}/;

  public static serializeNameAndPhone(userInfo: UserInfo): string {
    return btoa(
      userInfo.name +
      ':' +
      userInfo.phone +
      (userInfo.dossier ? (':' + userInfo.dossier) : '') +
      (userInfo.dossierId ? (':' + userInfo.dossierId) : '')
    );
  }

  public static deserializeNameAndPhone(token: string): UserInfo {
    const values = atob(token).split(':');
    if (values.length === 4) {
      return {
        name: values[values.length - 4],
        phone: values[values.length - 3],
        dossier: values[values.length - 2],
        dossierId: values[values.length - 1]
      };
    }
    else if (values.length === 3) {
      return {
        name: values[values.length - 3],
        phone: values[values.length - 2],
        dossier: values[values.length - 1],
      };
    }
    return {
      name: values[values.length - 2],
      phone: values[values.length - 1],
    };
  }

  public static dataURLtoFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  public static generateUuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static doesRectsIntersect(r1: DOMRect, r2: DOMRect): boolean {
    return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);
  }
}
