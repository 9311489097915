import { Entity } from './entity';
import { Client } from './client';
import { Expert } from './expert';
import { Session } from './session';
import { Image } from './image';
import { Type} from 'typeserializer';

export enum VisioStatut {
  WAITING = 0,
  ONGOING = 1,
  ENDED = 2
}

export class Visio extends Entity {
  rendezVousId?: number;

  statut: VisioStatut;

  @Type(Client)
  client: Client;

  @Type(Expert)
  expert?: Expert;

  @Type(Session)
  session: Session;

  @Type([Image])
  images: Image[];
}
