import { Component, Output, EventEmitter } from '@angular/core';
import { WebRTCService } from '../../../services/webrtc.service';
import { SettingsDialogInterface, SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent {

  @Output() takePhotoClicked = new EventEmitter();
  @Output() gotoGalleryClicked = new EventEmitter();
  @Output() switchCameraClicked = new EventEmitter();
  @Output() toggleMicrophoneClicked = new EventEmitter();

  constructor(
    public webRTCService: WebRTCService,
    private dialog: MatDialog
  ) {}

  public takePhoto() {
    this.takePhotoClicked.emit();
  }

  public gotoGallery() {
    this.gotoGalleryClicked.emit();
  }

  public switchCamera() {
    this.switchCameraClicked.emit();
  }

  public toggleMicrophone() {
    this.toggleMicrophoneClicked.emit();
  }

  public settings() {
    const data: SettingsDialogInterface = {
      publisher: this.webRTCService.currentPublisherObject
    };
    this.dialog.open(SettingsDialogComponent, {
      data,
      minWidth: '25vw'
    });
  }
}
