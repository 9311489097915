<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <button type="button" color="white" mat-mini-fab aria-label="Gallery" (click)="gotoGallery()">
      <mat-icon>insert_photo</mat-icon>
    </button>
    <button type="button" color="white" mat-mini-fab aria-label="Dummy" style="visibility: hidden;">
      <mat-icon>insert_photo</mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <button type="button" color="white" mat-fab aria-label="Take photo" (click)="takePhoto()">
      <mat-icon>photo_camera</mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <button type="button" color="white" mat-mini-fab aria-label="Gallery" (click)="settings()">
      <mat-icon>settings</mat-icon>
    </button>
    <button type="button" color="white" mat-mini-fab aria-label="Microphone ON/OFF" (click)="toggleMicrophone()">
      <mat-icon>{{ webRTCService.hasAudio ? 'mic' : 'mic_off' }}</mat-icon>
    </button>
  </div>
</div>
