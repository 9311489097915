import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CopyrightComponent } from './shared-components/copyright/copyright.component';
import { FullLogoComponent } from './shared-components/full-logo/full-logo.component';
import { ParameterComponent } from './shared-components/parameter/parameter.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BasicDialogComponent } from './shared-components/basic-dialog/basic-dialog.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        ParameterComponent,
        CopyrightComponent,
        FullLogoComponent,
        BasicDialogComponent
    ],
    imports: [
        TranslateModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatIconModule,
        MatCardModule,
        MatProgressBarModule,
        MatDividerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatDialogModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        DragDropModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    exports: [
        TranslateModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatIconModule,
        MatCardModule,
        MatProgressBarModule,
        MatDividerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatDialogModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        DragDropModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        ParameterComponent,
        CopyrightComponent,
        FullLogoComponent,
        BasicDialogComponent
    ]
})
export class SharedModule { }
