<div class="card-page">
  <mat-card fxFlex="1 1">
    <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="24px" class="m-16">
      <app-full-logo></app-full-logo>
      <form [formGroup]="form">
        <div fxLayout="column" fxLayoutGap="24px" class="mt-16">
          <mat-form-field appearance="outline" class="no-padding">
            <mat-icon matPrefix>face</mat-icon>
            <mat-label>{{ 'LOGIN.NAME_PLACEHOLDER' | translate }}</mat-label>
            <input matInput placeholder="{{ 'LOGIN.NAME_HINT' | translate }}" formControlName="name" type="text" (keypress)="onKeyPressed($event)" required/>
            <mat-error *ngIf="form.controls.name.hasError('required')"
              [innerHTML]="'LOGIN.NAME_ERROR_REQUIRED' | translate">
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-padding">
            <mat-icon matPrefix>phone</mat-icon>
            <mat-label>{{ 'LOGIN.PHONE_PLACEHOLDER' | translate }}</mat-label>
            <input matInput placeholder="{{ 'LOGIN.PHONE_HINT' | translate }}" formControlName="phone" type="tel" (keypress)="onKeyPressed($event)" required/>
            <mat-error *ngIf="form.controls.phone.hasError('required')"
              [innerHTML]="'LOGIN.PHONE_ERROR_REQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="!form.controls.phone.hasError('required') && form.controls.phone.hasError('wrongNumber')"
              [innerHTML]="'LOGIN.PHONE_ERROR_INVALID' | translate">
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-padding">
            <mat-icon matPrefix>folder_open</mat-icon>
            <mat-label>{{ 'LOGIN.DOSSIER_PLACEHOLDER' | translate }}</mat-label>
            <input matInput [placeholder]="'LOGIN.DOSSIER_HINT' | translate" formControlName="dossier" type="text" (keypress)="onKeyPressed($event)">
            <mat-error *ngIf="form.controls.dossier.hasError('pattern')"
              [innerHtml]="'LOGIN.DOSSIER_ERROR_INVALID' | translate">
            </mat-error>
          </mat-form-field>

          <!-- Dummy items to remove soft keyboard after pressing enter (it's 100% a hack) -->
          <input id="dummy" type="text" [readonly]="dummyStatus" (focusin)="dummyStatus = true"
            (focusout)="dummyStatus = false" />

          <div fxLayout="column" fxLayoutGap="16px" class="mt-16">
            <button class="tall" (click)="formSubmit('test')" [disabled]="!form.valid" type="button" mat-stroked-button color="primary">
              {{ 'LOGIN.TEST_BUTTON' | translate }}
            </button>
            <button class="tall" (click)="formSubmit('expertise')" [disabled]="!form.valid" type="button" mat-raised-button color="primary">
              {{ 'LOGIN.EXPERTISE_BUTTON' | translate }}
            </button>
          </div>
        </div>
      </form>
      <app-copyright class="mt-16"></app-copyright>
    </div>
  </mat-card>
</div>
