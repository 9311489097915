<div>
  <h4>Caméra</h4>
  <mat-form-field appearance="fill">
    <mat-label>Caméra</mat-label>
    <mat-select [(ngModel)]="selectedVideoInput" (selectionChange)="selectedVideoInputChanged()">
      <mat-option *ngFor="let videoInput of videoInputs" [value]="videoInput.deviceId">
        {{ videoInput.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Microphone</h4>
  <mat-form-field appearance="fill">
    <mat-label>Microphone</mat-label>
    <mat-select [(ngModel)]="selectedAudioInput" (selectionChange)="selectedAudioInputChanged()">
      <mat-option *ngFor="let audioInput of audioInputs" [value]="audioInput.deviceId">
        {{ audioInput.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Haut-parleurs</h4>
  <mat-form-field appearance="fill">
    <mat-label>Haut-parleurs</mat-label>
    <mat-select [(ngModel)]="selectedAudioOutput" (selectionChange)="selectedAudioOutputChanged()">
      <mat-option *ngFor="let audioOutput of audioOutputs" [value]="audioOutput.deviceId">
        {{ audioOutput.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Fermer</button>
</mat-dialog-actions>