import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './views/login/login.module';
import { TestModule } from './views/test/test.module';
import { ExpertiseModule } from './views/expertise/expertise.module';
import { LegalNoticeModule } from './views/legal-notice/legal-notice.module';
import { IncompatibleBrowserModule } from './views/incompatible-browser/incompatible-browser.module';
import { BrowserCompatibilityService } from './services/browser-compatibility.service';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiService } from './services/api.service';
import { PostExpertiseModule } from './views/post-expertise/post-expertise.module';
import { ThemeService } from './services/theme.service';
import { PhoneService } from './services/phone.service';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

Sentry.init({
  environment: environment.name,
  attachStacktrace: true,
  enabled: environment.production,
  dsn: environment.sentryDsn,
  release: '' + require('../../package.json').name + '@' + environment.version,
  beforeSend(event, hint) {
      const ignoredErrors = [
        '/analytics.js',
        'Non-Error exception',
        'Server returned code 4',
        'Server returned code 5',
        'OT_',
        'AbortError:'
      ];

      try {
        const errStr = event.exception?.values[0]?.type + ': ' + event.exception?.values[0]?.value;
        if (ignoredErrors.some(ie => errStr?.indexOf(ie) !== -1)) {
            // We want to ignore those kind of errors
            return null;
        }
      } catch (error) {}

      return event;
  }
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: Error | { originalError: Error; message: string }): void {
    console.error(error);

    if (error) {
        if (/Loading chunk \d+ failed/.test(error.message)) {
            window.location.reload();
        }

        if (Object.prototype.hasOwnProperty.call(error, 'originalError')) {
            Sentry.captureException((error as { originalError: Error}).originalError);
        } else {
            Sentry.captureException(error);
        }
    }
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    LoginModule,
    TestModule,
    ExpertiseModule,
    PostExpertiseModule,
    LegalNoticeModule,
    IncompatibleBrowserModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.trackingId)
  ],
  providers: [
    BrowserCompatibilityService,
    ApiService,
    ThemeService,
    PhoneService,
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
