import { NgModule } from '@angular/core';
import { ExpertiseComponent } from './expertise.component';
import { SharedModule } from '../shared.module';
import { ActionsComponent } from './actions/actions.component';
import { LadDialogComponent } from './lad-dialog/lad-dialog.component';
import { PointerComponent } from './pointer/pointer.component';
import { NgxToastedModule } from 'ngx-toasted';
import { LightboxModule } from 'ngx-lightbox';
import { MainComponent } from './main/main.component';
import { SecondaryComponent } from './secondary/secondary.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';

@NgModule({
    declarations: [
        ExpertiseComponent,
        ActionsComponent,
        LadDialogComponent,
        PointerComponent,
        MainComponent,
        SecondaryComponent,
        SettingsDialogComponent
    ],
    imports: [
        SharedModule,
        NgxToastedModule,
        LightboxModule
    ]
})
export class ExpertiseModule { }
