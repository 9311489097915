import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as OT from '@opentok/client';
import { Visio } from '../models/visio';

export enum DevicePermission {
  Undefined,
  NoDevicesFound,
  NoPermission,
  Ok
}

@Injectable()
export class WebRTCService implements OnDestroy {
  private visio: Visio;

  private apiKey: string;
  private sessionId: string;
  private sessionToken: string;

  private _mediaStream: MediaStream = null;

  private _session: BehaviorSubject<OT.Session> = new BehaviorSubject<OT.Session>(null);
  private _currentPublisher: BehaviorSubject<OT.Publisher> = new BehaviorSubject<OT.Publisher>(null);
  private _currentPublisherStream: BehaviorSubject<OT.Stream> = new BehaviorSubject<OT.Stream>(null);
  private _currentSubscribers: BehaviorSubject<Array<OT.Subscriber>> = new BehaviorSubject<Array<OT.Subscriber>>([]);
  private _currentStreams: BehaviorSubject<Array<OT.Stream>> = new BehaviorSubject<Array<OT.Stream>>([]);

  private _hasAudio = true;
  private _hasVideo = true;
  public permissionsStatus: BehaviorSubject<DevicePermission> = new BehaviorSubject<DevicePermission>(DevicePermission.Undefined);

  public onSignalEventEmitter = new EventEmitter<any>();
  public onConnectionCreatedEmitter = new EventEmitter<any>();
  public onConnectionDestroyedEmitter = new EventEmitter<any>();
  public onSessionConnectedEmitter = new EventEmitter<any>();
  public onSessionDisconnectedEmitter = new EventEmitter<any>();
  public onSessionReconnectingEmitter = new EventEmitter<any>();
  public onSessionReconnectedEmitter = new EventEmitter<any>();

  public onPublisherVideoDimensionChangedEmitter = new EventEmitter<any>();
  public onPublisherAudioLevelUpdatedEmitter = new EventEmitter<any>();

  public onPublisherStreamCreatedEmitter = new EventEmitter<OT.Stream>();
  public onPublisherStreamDestroyedEmitter = new EventEmitter<OT.Stream>();

  public onStreamCreatedEmitter = new EventEmitter<OT.Stream>();
  public onStreamDestroyedEmitter = new EventEmitter<OT.Stream>();

  public onTestStart = new EventEmitter<any>();
  public onTestEnd = new EventEmitter<any>();

  public isConnectedOnSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  getOT() {
    return OT;
  }

  setVisio(apiKey: string, visio: Visio, isGuest: boolean = false): Promise<OT.Session> {
    this.visio = visio;

    this.apiKey = apiKey;
    this.sessionId = visio.session.opentokSessionId;
    this.sessionToken = isGuest ? visio.session.opentokSessionTokenGuest : visio.session.opentokSessionTokenClient;

    if (this.apiKey && this.sessionId && this.sessionToken) {
      const session = this.getOT().initSession(this.apiKey, this.sessionId);

      if (session) {
        session.on('streamCreated', (event) => {
          this.addCurrentStreams(event.stream);
          this.onStreamCreatedEmitter.emit(event.stream);
        });

        session.on('streamDestroyed', (event) => {
          this.removeCurrentStreams(event.stream);
          this.onStreamDestroyedEmitter.emit(event.stream);
        });

        session.on('signal', (event) => {
          if (session.connection.connectionId === event.from.connectionId) {
            console.log('Signal emmited successfully', event);
          } else {
            console.log('Signal received', event);
            this.onSignalEventEmitter.emit(event);
          }
        });

        session.on('connectionCreated', (event) => {
          console.log('Connection created', event);
          this.onConnectionCreatedEmitter.emit(event);
        });

        session.on('connectionDestroyed', (event) => {
          console.log('Connection destroyed', event);
          this.onConnectionDestroyedEmitter.emit(event);
        });

        session.on('sessionConnected', (event) => {
          console.log('Connected to session', event);
          this.isConnectedOnSession.next(true);
          this.onSessionConnectedEmitter.emit(event);
        });

        session.on('sessionDisconnected', (event) => {
          console.log('Disconnected to session', event);
          this.isConnectedOnSession.next(false);
          this.onSessionDisconnectedEmitter.emit(event);
        });

        session.on('sessionReconnecting', (event) => {
          console.log('Reconnecting to session...', event);
          this.isConnectedOnSession.next(false);
          this.onSessionReconnectingEmitter.emit(event);
        });

        session.on('sessionReconnected', (event) => {
          console.log('Reconnected to session', event);
          this.isConnectedOnSession.next(true);
          this.onSessionReconnectedEmitter.emit(event);
        });
      }

      this._session.next(session);

      return Promise.resolve(session);
    } else {
      return Promise.reject('Something went wrong...');
    }
  }

  connect(): Promise<OT.Session> {
    if (!this.visio) {
      return Promise.reject('No visio specified');
    }

    const session = this._session.value;
    if (!session) {
      return Promise.reject('No session specified');
    }

    return new Promise((resolve, reject) => {
      session.connect(this.sessionToken, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }


  initPublisher(nativeElement, options: OT.PublisherProperties, callback: (err) => void) {
    const publisher = this.getOT().initPublisher(nativeElement, options, (err) => {
      if (err?.name === 'OT_NO_DEVICES_FOUND') {
        if (this.permissionsStatus.value !== DevicePermission.NoDevicesFound) {
          this.permissionsStatus.next(DevicePermission.NoDevicesFound);
        }
      }
      callback(err);
    });

    publisher.on('accessAllowed', (event) => {
      if (this.permissionsStatus.value !== DevicePermission.Ok) {
        this.permissionsStatus.next(DevicePermission.Ok);
      }
    });

    publisher.on('accessDenied', (event) => {
      if (this.permissionsStatus.value !== DevicePermission.NoPermission) {
        this.permissionsStatus.next(DevicePermission.NoPermission);
      }
    });

    publisher.on('audioLevelUpdated', (event) => {
      this.onPublisherAudioLevelUpdatedEmitter.emit(event);
    });

    publisher.on('videoDimensionsChanged', (event) => {
      this.onPublisherVideoDimensionChangedEmitter.emit(event);
    });

    publisher.on('streamCreated', (event) => {
      this._currentPublisherStream.next(event.stream);
      this.onPublisherStreamCreatedEmitter.emit(event.stream);
      this.addCurrentStreams(event.stream);
      this.onStreamCreatedEmitter.emit(event.stream);
    });

    publisher.on('streamDestroyed', (event) => {
      this._currentPublisherStream.next(null);
      this.onPublisherStreamDestroyedEmitter.emit(event.stream);
      this.removeCurrentStreams(event.stream);
      this.onStreamDestroyedEmitter.emit(event.stream);
    });

    this.setCurrentPublisher(publisher);

    return publisher;
  }

  publish(callback: (err) => void) {
    const session = this._session.value;
    if (!session || !session.connection) {
      console.error('Trying to publish while not being connected');
      callback(new Error('Trying to publish while not being connected'))
      return;
    }

    const publisher = this._currentPublisher.value;
    if (!publisher) {
      console.error('Trying to publish while no publisher has been initialized');
      callback(new Error('Trying to publish while no publisher has been initialized'))
      return;
    }

    session.publish(publisher, (err) => {
      if (err) {
        console.error(err);
        callback(err);
      }

      // Fix default microphone broken on Android
      if (publisher) {
        OT.getDevices((err, devices) => {
          const speakerPhoneAudioInput = devices.filter(d => d.kind == 'audioInput').find(d => d.label.toLowerCase().indexOf('speakerphone') != -1);
          if (speakerPhoneAudioInput) {
            publisher?.setAudioSource(speakerPhoneAudioInput.deviceId).then(console.log).catch(console.error);
          }
        });
      }
    });
  }

  unpublish() {
    const session = this._session.value;
    const publisher = this._currentPublisher.value;
    if (session && session.connection && publisher) {
      session.unpublish(publisher);
      this.setCurrentPublisher(null);
    }
  }

  subscribe(stream: OT.Stream, targetElement: HTMLElement, properties: OT.SubscriberProperties | any): Observable<OT.Subscriber> {
    const obs = new Observable<OT.Subscriber>(observer => {
      const session = this._session.value;
      if (!session || !session.connection) {
        observer.error(new Error('Trying to subscribe while not being connected'));
        observer.complete();
        return;
      }

      const subscribe = ((nbTry: number) => {
        const subscriber = session.subscribe(stream, targetElement, properties, (err) => {
          if (err) {
            console.error(err);
            if (nbTry > 0) {
              subscribe(nbTry - 1);
            } else {
              observer.error(err);
              observer.complete();
            }
          } else {
            this.addCurrenSubscribers(subscriber);
            observer.next(subscriber);
            observer.complete();
          }
        });
      });

      subscribe(4);
    });

    return obs;
  }

  unsubscribe(subscriber: OT.Subscriber) {
    const session = this._session.value;
    if (session && session.connection) {
      session.unsubscribe(subscriber);
    }
    this.removeCurrenSubscribers(subscriber);
  }

  disconnect() {
    const session = this._session.value;
    if (session) {
      session.disconnect();
    }
  }

  ngOnDestroy() {
    this.disconnect();

    try {
      const mediaStream = this._mediaStream;
      if (mediaStream) {
        mediaStream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  public get currentSession(): BehaviorSubject<OT.Session> {
    return this._session;
  }

  public get currentSessionObject(): OT.Session {
    return this._session.value;
  }

  public get currentPublisher(): BehaviorSubject<OT.Publisher> {
    return this._currentPublisher;
  }

  public get currentPublisherObject(): OT.Publisher {
    return this._currentPublisher.value;
  }

  public get currentPublisherStream(): BehaviorSubject<OT.Stream> {
    return this._currentPublisherStream;
  }

  public get currentPublisherStreamObject(): OT.Stream {
    return this._currentPublisherStream.value;
  }

  public get currentStreams(): BehaviorSubject<Array<OT.Stream>> {
    return this._currentStreams;
  }

  public get currentSubscribers(): BehaviorSubject<Array<OT.Subscriber>> {
    return this._currentSubscribers;
  }

  public get hasAudio(): boolean {
    return this._hasAudio;
  }

  public get hasVideo(): boolean {
    return this._hasVideo;
  }

  public toggleAudio() {
    const publisher = this._currentPublisher.value;
    if (publisher) {
      this._hasAudio = !this._hasAudio;
      publisher.publishAudio(this._hasAudio);
    }
  }

  public deactivateCamera() {
    this._hasVideo = false;
    const publisher = this._currentPublisher.value;
    if (publisher) {
      publisher.publishVideo(this._hasVideo);
    }
  }

  public toggleCamera() {
    this._hasVideo = !this._hasVideo;
    const publisher = this._currentPublisher.value;
    if (publisher) {
      publisher.publishVideo(this._hasVideo);
    }
  }

  private setCurrentPublisher(publisher: OT.Publisher) {
    if (this._currentPublisher.value) {
      this._currentPublisher.value.destroy();
    }

    this._currentPublisher.next(publisher);
  }

  private addCurrenSubscribers(subscriber: OT.Subscriber) {
    let newVal = this._currentSubscribers.getValue();
    if (newVal == null) {
      newVal = [];
    }
    newVal.push(subscriber);
    this._currentSubscribers.next(newVal);

    if (subscriber) {
      subscriber.on('connected' as any, (event) => {
        console.log('Subscriber connected', event);
      });

      subscriber.on('disconnected' as any, (event) => {
        console.log('Subscriber disconnected', event);
      });

      subscriber.on('destroyed' as any, (event) => {
        console.log('Subscriber destroyed', event);
      });
    }
  }

  private removeCurrenSubscribers(subscriber: OT.Subscriber) {
    let newVal = this._currentSubscribers.getValue();
    if (newVal == null) {
      newVal = [];
    }
    const idx = newVal.indexOf(subscriber);
    if (idx > -1) {
      newVal.splice(idx, 1);
      this._currentSubscribers.next(newVal);
    }

    subscriber.off('connected', null);
    subscriber.off('disconnected' as any, null);
    subscriber.off('destroyed' as any, null);
  }

  public putStreamOnTop(stream: OT.Stream) {
    const streams = this._currentStreams.getValue();
    const index = streams.findIndex((s: OT.Stream) => stream && s && stream.streamId === s.streamId);
    if (index !== -1) {
      streams.splice(index, 1);
      streams.push(stream);
      this._currentStreams.next(streams);
    }
  }

  private addCurrentStreams(stream: OT.Stream) {
    let newVal = this._currentStreams.getValue();
    if (newVal == null) {
      newVal = [];
    }
    newVal.push(stream);
    this._currentStreams.next(newVal);
  }

  private removeCurrentStreams(stream: OT.Stream) {
    let newVal = this._currentStreams.getValue();
    if (newVal == null) {
      newVal = [];
    }
    const idx = newVal.indexOf(stream);
    if (idx > -1) {
      newVal.splice(idx, 1);
      this._currentStreams.next(newVal);
    }
  }
}
