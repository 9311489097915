import { Entity } from './entity';

export class InfoClient extends Entity {
  plateform?: Plateform;
  phoneType?: string;
  latitude?: number;
  longitude?: number;
  connectionType?: ConnectionType;
  batteryLevel?: number;
  signalStrength?: number;
  gpsPerm?: boolean;
}

export enum Plateform {
  ANDROID = 0,
  IOS = 1,
  WEB = 2
}

export enum ConnectionType {
  WIFI = 0,
  CELLULAR = 1,
  UNKNOWN = 2
}
