import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class ExpertComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.document.location.href = 'https://wsvisio.adenes.eu/VisioXpertExternalServicerest.svc/ConnectVisio';
  }
}
