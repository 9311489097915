import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-full-logo',
  templateUrl: './full-logo.component.html',
  styleUrls: ['./full-logo.component.scss']
})
export class FullLogoComponent {
  @Input() linkActive = true;

  platform = environment.plateform;
}
